<template>
  <div id="materials-page">
    <ModalWarningDefaultRoute
      :currentName="currentName"
      :isMultipleDefaultDelete="isMultipleDefaultDelete"
      :parentName="
        dragList.find((e) => {
          if (!e.isMaterials) return e.id === currentParentId;
        }) || ''
      "
      @confirm="deleteItems"
    />
    <modal-delete
      :level="currentLevel"
      :name="currentName"
      @confirm="deleteItems"
    />
    <modal-resource
      :device="device"
      :modalTitle="currentName"
      :currentId="currentId"
      @updateList="getMaterials"
    />
    <modal-duplicate
      :device="device"
      :modalTitle="modalDuplicateName"
      :currentId="currentId"
      :currentLevel="currentLevel"
      :currentName="currentName"
      @updateList="getMaterials"
    />
    <modal-route
      :device="device"
      :modalTitle="modalRouteName"
      :currentId="currentId"
      :currentParentId="currentParentId"
      :tagsList="tagsList"
      @updateList="getMaterials"
    />
    <modal-manage-tags :device="device" />
    <modal-link-materials
      :modalTitle="currentName"
      :device="device"
      :isEdit="isEditMaterials"
      :currentId="currentId"
      :currentParentId="currentParentId"
      @updateList="getMaterials"
    />
    <modal-add-edit-material-and-operation
      :modalTitle="modalTitle"
      :device="device"
      :currentId="currentId"
      :isOperation="isOperation"
      :workcentersList="workcentersList"
      :tagsList="tagsList"
      :statusList="statusList"
      :unitMeasureList="unitMeasureList"
      :customFields="customFields"
      @updateList="getMaterials"
    />
    <div
      class="h-100"
      v-if="dragList.length === 0 && !noItemsFound"
    >
      <div class="no-items h-100">
        <EmptyBoxIcon class="empty-box" />
        <p class="title">{{ $t('MaterialsPage.NoMaterialsTitle') }}</p>
        <p class="subtitle">
          {{ $t('MaterialsPage.NoMaterialsSubtitle') }}
        </p>
        <b-button
          class="btn-new-materials"
          @click="changeModalName('new')"
        >
          <AddIcon class="add-icon" />
          {{ $t('MaterialsPage.NewMaterial') }}
        </b-button>
      </div>
    </div>
    <div
      v-else
      class="h-100"
    >
      <div class="header-block">
        <div class="first-line">
          <div class="title">
            {{ $t('PagesViews.Manufacturing') }}
          </div>
          <div class="add-materials">
            <b-button
              class="btn-tags"
              @click="UPDATE_MODAL_MANAGE_TAGS_TOGGLE(true)"
            >
              <TagIcon class="tag-icon" />
              Tags
            </b-button>
            <b-button
              class="btn-new-materials"
              @click="changeModalName('new')"
            >
              <AddIcon class="add-icon" />
              {{ $t('MaterialsPage.NewMaterial') }}
            </b-button>
          </div>
        </div>
        <div class="second-line">
          <div class="search-input">
            <div class="div-custom-input input__materials">
              <div class="icon-block">
                <SearchIcon class="search-icon" />
              </div>
              <b-form-tags
                class="input-search-materials"
                ref="formTagMaterials"
                v-model="filter.textFilter"
                duplicate-tag-text=""
                :placeholder="filter.textFilter.length === 0 ? $t('SearchPlaceholderDefault') : ''"
                @input="getMaterials()"
                remove-on-delete
              />
            </div>
          </div>
        </div>
      </div>
      <div class="body-block">
        <div class="h-100">
          <CollapseDraggable
            isMaterials
            :list="dragList"
            :dragList="dragList"
            :dragListStart="dragListStart"
            :optionIcons="optionIcons"
            :levelIcons="levelIcons"
            :selectedChecks="selectedChecks"
            :forceRender="forceRender"
            @SelectedToZero="UPDATE_SELECTED_CHECKS(0)"
            @deleteItems="(ids) => deleteItems(ids)"
            @updatedList="updatedList"
            @checkedCounter="checkedCounter"
            @getEditInfos="(id, level, _, parentId) => getEditInfos(id, level, _, parentId)"
            @undoChangesTree="undoChangesTree"
            @saveActualTree="saveActualTree"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState, mapMutations } from 'vuex';
  import { BFormTags, BButton } from 'bootstrap-vue';
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
  import SearchIcon from '@core/assets/icons/search-icon.svg';
  import InfoIcon from '@core/assets/icons/info-outline-icon.svg';
  import MaterialIcon from '@core/assets/icons/material-hierarchy-icon.svg';
  import OptionsIcon from '@core/assets/icons/options-icon.svg';
  import RouteIcon from '@core/assets/icons/route-hierarchy-icon.svg';
  import OperationIcon from '@core/assets/icons/operation-hierarchy-icon.svg';
  import UsedMaterialIcon from '@core/assets/icons/used-material-icon.svg';
  import NewIcon from '@core/assets/icons/new-icon.svg';
  import LinkIcon from '@core/assets/icons/integration-icon.svg';
  import DuplicateIcon from '@core/assets/icons/duplicate-icon.svg';
  import DeleteIcon from '@core/assets/icons/delete-icon.svg';
  import AddIcon from '@core/assets/icons/add-icon.svg';
  import WorkcenterIcon from '@core/assets/icons/workcenter-hierarchy-icon.svg';
  import TagIcon from '@core/assets/icons/tag-icon.svg';
  import EditIcon from '@core/assets/icons/edit-icon.svg';
  import OpenIcon from '@core/assets/icons/open-icon.svg';
  import EmptyBoxIcon from '@core/assets/icons/empty-box-icon.svg';
  import ModalResource from './components/workcenterModals/ModalResource.vue';
  import ModalDuplicate from './components/ModalDuplicate.vue';
  import ModalRoute from './components/ModalRoute.vue';
  import ModalDelete from './components/ModalDelete.vue';
  import ModalWarningDefaultRoute from './components/ModalWarningDefaultRoute.vue';
  import ModalManageTags from './components/ModalManageTags.vue';
  import ModalLinkMaterials from './components/ModalLinkMaterials.vue';
  import ModalAddEditMaterialAndOperation from './components/addEditMaterialsOperationsModals/ModalAddEditMaterialAndOperation.vue';
  import CollapseDraggable from '@/@core/components/collapse-draggable/CollapseDraggable.vue';

  export default {
    components: {
      BFormTags,
      BButton,
      ToastificationContent,
      ModalResource,
      ModalDuplicate,
      ModalRoute,
      ModalDelete,
      ModalWarningDefaultRoute,
      ModalManageTags,
      ModalLinkMaterials,
      ModalAddEditMaterialAndOperation,
      CollapseDraggable,
      SearchIcon,
      InfoIcon,
      AddIcon,
      TagIcon,
      WorkcenterIcon,
      EmptyBoxIcon,
      OptionsIcon,
      MaterialIcon,
      RouteIcon,
      OperationIcon,
      UsedMaterialIcon,
      OpenIcon,
      DuplicateIcon,
      NewIcon,
      LinkIcon,
      EditIcon,
      DeleteIcon
    },
    data() {
      return {
        filter: {
          textFilter: []
        },
        isOperation: false,
        noItemsFound: false,
        modalRouteName: '',
        modalDuplicateName: '',
        modalTitle: '',
        currentId: null,
        currentParentId: null,
        currentLevel: null,
        currentName: null,
        isEditMaterials: false,
        forceRender: 0,
        isMultipleDefaultDelete: false,
        errorCode: [],
        allCheckedItems: [],
        workcentersList: [],
        customFields: [],
        industrialParametersMaterial: [],
        industrialParametersOperation: [],
        tagsList: [],
        statusList: [],
        unitMeasureList: [],
        levelIcons: [
          {
            title: 'Material',
            techName: 'Material',
            level: 1,
            icon: MaterialIcon
          },
          {
            title: 'MaterialsPage.RouteSingular',
            techName: 'Route',
            level: 2,
            icon: RouteIcon
          },
          {
            title: 'orderView.Operation',
            techName: 'Operation',
            level: 3,
            icon: OperationIcon
          },
          {
            title: 'Material2',
            techName: 'UsedMaterial',
            level: 4,
            icon: UsedMaterialIcon
          }
        ],
        optionIcons: [
          {
            title: 'Info',
            icon: InfoIcon,
            click: () => {}
          },
          {
            title: 'StructurePage.WorkCenter',
            icon: WorkcenterIcon,
            showOnLast: 3,
            click: (e) => {
              this.currentName = e.name;
              this.currentId = e.id;
              this.UPDATE_MODAL_RESOURCE_TOGGLE(true);
            }
          },
          {
            title: 'MaterialsPage.OpenMaterial',
            icon: OpenIcon,
            showOnLast: 4,
            click: (e) => {
              const material = document.querySelector(`.level-1.${e.name.replaceAll(' ', '')}`);
              material &&
                material.scrollIntoView({
                  behavior: 'smooth',
                  block: 'start',
                  inline: 'nearest'
                });
            }
          },
          {
            title: 'DepartmentPage.Actions',
            icon: OptionsIcon,
            removeOnMobile: true,
            click: () => {},
            actions: [
              {
                title: 'DepartmentPage.New',
                icon: NewIcon,
                click: (e) => {
                  const levelModal = this.levelIcons.find((e) => e.level === this.currentLevel + 1);
                  this.modalTitle = `${this.$t(e.title)} ${this.$t(levelModal.title)}`;
                  this.isOperation = false;
                  if (levelModal.techName === 'Route') {
                    this.modalRouteName = 'MaterialsPage.NewRoute';
                    this.UPDATE_MODAL_ROUTE_TOGGLE(true);
                  }
                  if (levelModal.techName === 'Operation') {
                    this.isOperation = true;
                    this.customFields = this.industrialParametersOperation;
                    this.UPDATE_MODAL_ADD_EDIT_MATERIAL_OPERATION_TOGGLE(true);
                  }
                }
              },
              {
                title: 'MaterialsPage.LinkMaterial',
                icon: LinkIcon,
                click: (e) => {
                  this.currentName = this.getCurrentRow(this.dragList).name;
                  this.UPDATE_MODAL_LINK_MATERIALS_TOGGLE(true);
                }
              },
              {
                title: 'MaterialsPage.UnlinkMaterial',
                icon: LinkIcon,
                click: () => {
                  this.unLinkMaterial();
                }
              },
              {
                title: 'DepartmentPage.Edit',
                icon: EditIcon,
                click: (e) => {
                  const levelModal = this.levelIcons.find((e) => e.level === this.currentLevel);
                  this.modalTitle = `${this.$t(e.title)} ${this.$t(levelModal.title)}`;
                  this.isOperation = false;
                  if (levelModal.techName === 'Material') {
                    this.changeModalName('edit');
                  }
                  if (levelModal.techName === 'Route') {
                    this.modalRouteName = 'MaterialsPage.EditRoute';
                    this.UPDATE_MODAL_ROUTE_TOGGLE(true);
                  }
                  if (levelModal.techName === 'Operation') {
                    this.isOperation = true;
                    this.changeModalName('edit');
                  }
                  if (levelModal.level === 4) {
                    this.isEditMaterials = true;
                    this.currentName = this.getCurrentRow(this.dragList).name;
                    this.UPDATE_MODAL_LINK_MATERIALS_TOGGLE(true);
                  }
                }
              },
              {
                title: 'ProfilesPage.Duplicate',
                icon: DuplicateIcon,
                click: (e) => {
                  const levelModal = this.levelIcons.find((e) => e.level === this.currentLevel);
                  this.modalTitle = `${this.$t(e.title)} ${this.$t(levelModal.title)}`;
                  this.currentName = `${this.getCurrentRow(this.dragList).name} (${this.$t(
                    'MaterialsPage.Duplicated'
                  )})`;
                  this.isOperation = false;
                  this.modalDuplicateName = `${this.$t('ProfilesPage.Duplicate')} ${this.$t(
                    levelModal.title
                  )}`;
                  this.UPDATE_MODAL_DUPLICATE_TOGGLE(true);
                }
              },
              {
                title: 'DepartmentPage.Delete',
                icon: DeleteIcon,
                click: () => {
                  this.currentName = this.getCurrentRow(this.dragList).name;
                  this.UPDATE_MODAL_DELETE(true);
                }
              }
            ]
          }
        ],
        prefix: localStorage.getItem('prefix'),
        device:
          window.innerWidth > 768
            ? 'desktop'
            : window.innerWidth > 480 && window.innerWidth <= 768
            ? 'tablet'
            : 'mobile'
      };
    },
    created() {
      window.addEventListener('resize', this.handleResize);
    },
    destroyed() {
      window.removeEventListener('resize', this.handleResize);
    },
    async mounted() {
      this.UPDATE_FLAG_SHOW_OVERLAY(true);
      await this.$http2.get('/api/myconfig/material/context').then((response) => {
        this.workcentersList = response.data.workcenters.map((item) => ({
          value: item.id,
          text: item.name
        }));
        this.tagsList = response.data.tags
          .map((item) => ({
            value: item.id,
            text: item.name
          }))
          .sort((a, b) => a.text.localeCompare(b.text));
        this.statusList = response.data.status.map((item) => ({
          value: item.id,
          text: item.name
        }));
        this.unitMeasureList = response.data.materialUnits.map((item) => ({
          value: item.id,
          text: item.name
        }));
        this.industrialParametersMaterial = response.data.industrialParametersMaterial;
        this.industrialParametersOperation = response.data.industrialParametersOperation;
      });
      this.getMaterials();
      this.activeCarousel();
    },
    methods: {
      ...mapMutations('app', ['UPDATE_FLAG_SHOW_OVERLAY']),
      ...mapMutations('departments', ['UPDATE_MODAL_DELETE_SNACK_BAR']),
      ...mapMutations('materials', [
        'UPDATE_MODAL_RESOURCE_TOGGLE',
        'UPDATE_MODAL_DUPLICATE_TOGGLE',
        'UPDATE_MODAL_ROUTE_TOGGLE',
        'UPDATE_MODAL_MANAGE_TAGS_TOGGLE',
        'UPDATE_MODAL_LINK_MATERIALS_TOGGLE',
        'UPDATE_MODAL_ADD_EDIT_MATERIAL_OPERATION_TOGGLE',
        'UPDATE_DRAG_LIST',
        'UPDATE_DRAG_LIST_START',
        'UPDATE_MODAL_DELETE',
        'UPDATE_SELECTED_CHECKS',
        'UPDATE_MODAL_WARNING_DEFAULT_ROUTE'
      ]),
      handleResize() {
        this.device =
          window.innerWidth > 768
            ? 'desktop'
            : window.innerWidth > 480 && window.innerWidth <= 768
            ? 'tablet'
            : 'mobile';
      },
      activeCarousel() {
        const vm = this;
        $(function () {
          document
            .getElementsByClassName('input-search-materials')[0]
            .setAttribute('tabIndex', '-1');

          const slider = document.querySelector('.b-form-tags-list');
          let mouseDown = false;
          let startX, scrollLeft;

          let startDragging = function (e) {
            mouseDown = true;
            startX = e.pageX - slider.offsetLeft;
            scrollLeft = slider.scrollLeft;
          };
          let stopDragging = function () {
            mouseDown = false;
          };

          slider.addEventListener('mousemove', (e) => {
            e.preventDefault();
            if (!mouseDown) {
              return;
            }
            const x = e.pageX - slider.offsetLeft;
            const scroll = x - startX;
            slider.scrollLeft = scrollLeft - scroll;
          });

          slider.addEventListener('mousedown', startDragging, false);
          slider.addEventListener('mouseup', stopDragging, false);
          slider.addEventListener('mouseleave', stopDragging, false);

          $('.b-form-tags-input').blur((event) => {
            let input = vm.$refs.formTagMaterials;
            input.addTag(event.target.value);
          });
        });
      },
      addLevelByChild(arr, level = 1) {
        arr.map((e) => {
          e.level = level;
          if (e.child.length > 0) {
            e.child.forEach((f) => (f.parentId = e.id));
          }
          if (e.child.length) this.addLevelByChild(e.child, level + 1);
        });
      },
      addChildWhenUndefined(objArr) {
        objArr.forEach((obj) => {
          if (!obj.hasOwnProperty('child')) {
            obj.child = [];
            obj.isMaterials = true;
          }
          this.addChildWhenUndefined(obj.child);
        });
      },
      previousStep(code) {
        this.errorCode = [...this.errorCode, code];
        if (!code || code === 64 || code === 72) {
          this.UPDATE_MODAL_ADD_MATERIAL_TOGGLE(true);
          this.UPDATE_MODAL_ADD_ATTACHMENTS_TOGGLE(false);
        }
      },
      async updatedList(_, ids, destinyId) {
        this.allCheckedItems = [];
        this.getAllCheckedItems(this.dragList);
        this.UPDATE_FLAG_SHOW_OVERLAY(true);

        const parentId = this.findParentId(this.dragList, Number(destinyId));
        const parentLevel = this.findLevelById(this.dragList, parentId);

        const payload = {
          destiny: {
            id: parentId,
            level: parentLevel
          },
          elements: ids
        };

        await this.$http2
          .put(`/api/myconfig/material/movimentation`, payload)
          .then(() => {
            this.unCheckAll(this.dragList);
            this.getMaterials();
          })
          .catch((error) => {
            error.response.data.errors.map((e) => {
              const hasDefaultRouteIdError = 113;
              if (e.id === hasDefaultRouteIdError) {
                this.showToast(
                  this.$t('IntegrationPage.Error'),
                  'SmileIcon',
                  this.$t('MaterialsPage.ErrorDefaultRoute'),
                  'danger'
                );
              } else {
                this.showToast(this.$t('IntegrationPage.Error'), 'SmileIcon', e.message, 'danger');
              }
            });
            this.UPDATE_DRAG_LIST(JSON.parse(JSON.stringify(this.dragListStart)));
            this.forceRender = this.forceRender + 1;
          })
          .finally(() => {
            this.UPDATE_FLAG_SHOW_OVERLAY(false);
          });
      },
      getCurrentRow(list) {
        if (!list || list.length === 0) {
          return null;
        }

        for (const item of list) {
          if (item.id === this.currentId) {
            return item;
          }

          if (!item.isMaterials && item.child && item.child.length > 0) {
            const foundItem = this.getCurrentRow(item.child);
            if (foundItem) {
              return foundItem;
            }
          }
        }

        return null;
      },
      unCheckAll(list) {
        for (const obj of list) {
          obj['checked'] = false;
          if (obj.child && obj.child.length > 0) {
            this.unCheckAll(obj.child);
          }
        }
        this.UPDATE_SELECTED_CHECKS(0);
      },
      checkedCounter(counter) {
        this.UPDATE_SELECTED_CHECKS(counter);
      },
      findLevelById(array, targetId) {
        for (const obj of array) {
          if (obj.child && obj.child.some((child) => child.id === targetId)) {
            const targetChild = obj.child.find((child) => child.id === targetId);
            if (targetChild && !targetChild.isMaterials) {
              return obj.level + 1;
            }
          }

          const nestedLevel = this.findLevelById(obj.child || [], targetId);
          if (nestedLevel !== 1) {
            return nestedLevel;
          }
        }
        return 1;
      },
      findParentId(array, targetId) {
        for (const obj of array) {
          if (obj.child && obj.child.some((child) => child.id === targetId)) {
            return obj.id;
          }
          const nestedParentId = this.findParentId(obj.child || [], targetId);
          if (nestedParentId) {
            return nestedParentId;
          }
        }
        return null;
      },
      getAllCheckedItems(list) {
        list.map((e) => {
          if (e.checked) {
            this.allCheckedItems.push(e.id);
          }
          if (e.child) this.getAllCheckedItems(e.child);
        });
      },
      showToast(title, icon, text, variant) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: title,
            icon: icon,
            text: text,
            variant
          }
        });
      },
      changeModalName(type) {
        if (type === 'new' && !this.isOperation) {
          this.customFields = this.industrialParametersMaterial;
          this.modalTitle = 'MaterialsPage.NewMaterial';
        }
        if (type === 'new' && this.isOperation) {
          this.customFields = this.industrialParametersOperation;
          this.modalTitle = 'MaterialsPage.NewOperation';
        }
        if (type === 'edit' && !this.isOperation) {
          this.customFields = this.industrialParametersMaterial;
          this.modalTitle = 'MaterialsPage.EditMaterial';
        }
        if (type === 'edit' && this.isOperation) {
          this.customFields = this.industrialParametersOperation;
          this.modalTitle = 'MaterialsPage.EditOperation';
        }
        this.UPDATE_MODAL_ADD_EDIT_MATERIAL_OPERATION_TOGGLE(true);
      },
      async unLinkMaterial() {
        const payload = [this.currentId];
        await this.$http2
          .delete(`api/myconfig/material/operation/${this.currentParentId}/remove-materials`, {
            data: payload
          })
          .then((response) => {
            this.showToast(
              this.$t('IntegrationPage.success'),
              'SmileIcon',
              this.$t('MaterialsPage.MaterialsUnlinked'),
              'success'
            );
          })
          .catch((error) => {
            this.showToast(
              this.$t('IntegrationPage.Error'),
              'SmileIcon',
              error.response.data.message,
              'danger'
            );
          })
          .finally(() => {
            this.getMaterials();
          });
      },
      undoChangesTree() {
        this.showToast(
          this.$t('Error'),
          'FrownIcon',
          this.$t('StructurePage.ErrorToastText2'),
          'danger'
        );
        this.UPDATE_DRAG_LIST(JSON.parse(JSON.stringify(this.dragListStart)));
        this.forceRender = this.forceRender + 1;
      },
      saveActualTree() {
        this.UPDATE_DRAG_LIST_START(JSON.parse(JSON.stringify(this.dragList)));
      },
      getEditInfos(id, level, _, parentId) {
        this.currentId = id;
        this.currentParentId = parentId ? parentId : id;
        this.currentLevel = level;
      },
      deleteItems(ids) {
        const hasDefaultRouteTrue = ids
          ? ids.filter((e) => e.defaultRoute)
          : this.getCurrentRow(this.dragList).default;

        const allMatch = ids
          ? hasDefaultRouteTrue.every((item2) => ids.some((item1) => item1.id === item2.parentId))
          : false;

        if (hasDefaultRouteTrue && !!!allMatch) {
          this.isMultipleDefaultDelete = ids ? true : false;
          this.UPDATE_MODAL_DELETE(false);
          this.UPDATE_MODAL_DELETE_SNACK_BAR(false);
          this.UPDATE_MODAL_WARNING_DEFAULT_ROUTE(true);
          return;
        }

        const payload = ids
          ? ids
          : [
              {
                level: this.currentLevel,
                id: this.currentId
              }
            ];
        let toastText = '';

        if (ids) {
          toastText = this.$t('DepartmentPage.DeletedItems');
        } else {
          switch (this.currentLevel) {
            case 1:
            case 4:
              toastText = this.$t('MaterialsPage.MaterialDeleted');
              break;
            case 2:
              toastText = this.$t('MaterialsPage.RouteDeleted');
              break;
            case 3:
              toastText = this.$t('MaterialsPage.OperationDeleted');
              break;
            default:
              break;
          }
        }

        this.routeDelete(payload, toastText);
      },
      openModalDefault() {
        this.$swal({
          title: this.$t('ResourceStatus.Attention'),
          showCloseButton: true,
          showCancelButton: false,
          confirmButtonText: this.$t('MaterialsPage.GotIt'),
          html: `<div></div>`,
          customClass: {
            container: 'swal-conection',
            confirmButton: 'btn btn-primary'
          },
          buttonsStyling: false
        });
      },
      async routeDelete(payload, toastText) {
        await this.$http2
          .delete(`/api/myconfig/material`, { data: payload })
          .then(() => {
            this.showToast(this.$t('IntegrationPage.success'), 'SmileIcon', toastText, 'success');
            this.forceRender = this.forceRender + 1;
            this.UPDATE_MODAL_DELETE(false);
            this.UPDATE_MODAL_DELETE_SNACK_BAR(false);
            this.UPDATE_SELECTED_CHECKS(0);
            this.getMaterials();
          })
          .catch((error) => {
            error.response.data.errors.map((e) => {
              this.showToast(this.$t('Error'), 'FrownIcon', e.message, 'danger');
            });
          })
          .finally(() => {
            this.UPDATE_FLAG_SHOW_OVERLAY(false);
          });
      },
      async getMaterials() {
        this.UPDATE_FLAG_SHOW_OVERLAY(true);
        const textFilterQuery = this.filter.textFilter;
        await this.$http2
          .get(`/api/myconfig/material?textFilter=${textFilterQuery}`)
          .then((response) => {
            this.noItemsFound = !!textFilterQuery.length;
            const updatedArr = response.data;
            this.forceRender = this.forceRender + 1;
            this.addChildWhenUndefined(updatedArr);
            this.addLevelByChild(updatedArr);
            this.UPDATE_DRAG_LIST(updatedArr);
            this.UPDATE_SELECTED_CHECKS(0);
            this.saveActualTree();
            this.UPDATE_FLAG_SHOW_OVERLAY(false);
          });
      }
    },
    computed: {
      ...mapState('materials', {
        dragList: 'dragList',
        dragListStart: 'dragListStart',
        selectedChecks: 'selectedChecks',
        isModalLinkMaterialsOpen: 'isModalLinkMaterialsOpen',
        isModalAddEditOpen: 'isModalAddEditOpen'
      })
    },
    watch: {
      isModalLinkMaterialsOpen(v) {
        if (!v) this.isEditMaterials = false;
      },
      isModalAddEditOpen(v) {
        if (!v) this.isOperation = false;
      }
    }
  };
</script>
<style lang="scss">
  #materials-page {
    padding: 16px 0;
    border-radius: 6px;
    background: #fff;
    box-shadow: 0px 4px 24px 0px rgba(58, 46, 38, 0.1);

    @media (max-width: 768px) {
      margin-top: 24px;
    }

    &:has(.no-items) {
      height: calc(100vh - 136px);
    }

    .no-items {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 6px;
      margin: 0 16px;
      border: 1px solid #cfc4be;

      .empty-box {
        width: 80px;
        height: 80px;
      }

      .title {
        font-size: 16px;
        font-weight: 600;
        line-height: 26px;
        color: #4c4541;
        margin: 0;
      }

      .subtitle {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: #998f8a;
        padding: 4px 0 12px;
        margin: 0;
      }

      .btn-new-materials {
        border-color: #974900 !important;
        background-color: #974900 !important;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        padding: 8px 21px;
        line-height: 20px;
        letter-spacing: 0.175px;

        .add-icon {
          width: 14px;
          height: 14px;
          fill: #fff;
        }
      }

      @media (max-width: 480px) {
        .empty-box {
          width: 40px;
          height: 40px;
        }

        .title {
          font-size: 14px;
        }

        .subtitle {
          font-size: 12px;
          text-align: center;
          padding: 4px 48px 12px;
          line-height: 16px;
        }

        .btn-new-materials {
          padding: 3px 14px;
          color: #fff;
          font-size: 12px;
          font-weight: 600;
          line-height: 20px;
          letter-spacing: 0.15px;

          .add-icon {
            width: 12px;
            height: 12px;
          }
        }
      }
    }

    .header-block {
      padding: 0 16px;
      .first-line {
        display: flex;
        justify-content: space-between;
        gap: 16px;

        .title {
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
        }
        .add-materials {
          display: flex;
          gap: 16px;

          .btn-new-materials {
            border-color: #974900 !important;
            background-color: #974900 !important;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 8px;
            width: 100% !important;
            min-width: 136px;
            padding: 3px 14px !important;
            font-size: 12px;
            font-weight: 600;
            line-height: 20px;
            letter-spacing: 0.0125em;

            .add-icon {
              width: 12px;
              height: 12px;
              fill: #fff;
            }
          }

          .btn-tags {
            border-color: #974900 !important;
            background-color: #fff !important;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 4px;
            min-width: 73px;
            padding: 3px 14px !important;
            font-size: 12px;
            font-weight: 600;
            line-height: 20px;
            letter-spacing: 0.0125em;
            color: #974900 !important;
          }

          @media (max-width: 480px) {
            flex-direction: column;
            gap: 8px;
          }
        }

        @media (max-width: 480px) {
          flex-direction: column;
        }
      }
      .second-line {
        .search-input {
          display: flex;
          align-items: center;
          padding: 16px 0 8px;

          .div-custom-input {
            display: flex;
            align-items: center;
            border-radius: 4px;
            width: 100%;
            overflow: hidden;
            border: 1px solid #cfc4be;
            .icon-block {
              display: flex;
              align-items: center;
              padding-left: 14px;
              height: 14px;
              .search-icon {
                width: 14px;
                height: 14px;
                fill: #cfc4be;
              }
            }
            .input-search-materials {
              width: 100% !important;
              border: none;
              overflow: hidden;

              &.focus {
                box-shadow: none;
              }
              &.b-form-tags {
                padding: 0 !important;
                display: flex;
                align-items: center !important;
                .b-form-tags-list {
                  width: 100% !important;
                  margin-left: 14px !important;
                  margin-top: 0;
                  height: 100% !important;
                  flex-wrap: nowrap !important;
                  display: flex !important;
                  align-items: center !important;
                  overflow: scroll;
                  overflow-y: hidden;
                  cursor: grabbing;
                  cursor: -webkit-grabbing;

                  &::-webkit-scrollbar {
                    display: none !important;
                  }
                  .b-form-tags-field {
                    margin: 9px 0;
                  }
                }
              }

              .b-form-tags-button {
                display: none;
              }

              .b-form-tag {
                background-color: #974900;
                margin-right: 0.6rem;
                padding: 2px 8px !important;
                font-size: 13px;
              }
            }
          }
        }
      }
    }
    .body-block {
      &:has(.block-no-results) {
        height: calc(100% - 90px);
      }
    }
    &:has(.body-block .block-no-results) {
      height: calc(100vh - 135px);
    }
    &:has(.body-block .no-items) {
      height: calc(100vh - 135px);
    }
  }
</style>
